import React, { useState, useEffect } from 'react';
import axios from 'axios';

function OrderPage() {
  const [orders, setOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [pickedUpOrders, setPickedUpOrders] = useState([]);
  const [activeTab, setActiveTab] = useState('paid'); // Sekme kontrolü için state

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://app.gyrosnj.com/orders');
        const allOrders = response.data;
        setOrders(allOrders);
        setPendingOrders(allOrders.filter(order => order.status === 'pending'));
        setPickedUpOrders(allOrders.filter(order => order.status === 'picked_up'));
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const markAsPickedUp = async (orderId) => {
    try {
      await axios.put(`https://app.gyrosnj.com/orders/${orderId}`, { status: 'picked_up' });
      const updatedOrders = orders.map(order =>
        order._id === orderId ? { ...order, status: 'picked_up' } : order
      );
      setOrders(updatedOrders);
      setPendingOrders(updatedOrders.filter(order => order.status === 'pending'));
      setPickedUpOrders(updatedOrders.filter(order => order.status === 'picked_up'));
    } catch (error) {
      console.error('Error marking order as picked up:', error);
    }
  };

  const renderOrderList = (orderList) => (
    orderList
      .sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate))
      .map(order => (
        <div key={order._id} className="bg-white p-6 rounded-md shadow-md mb-4">
          <h2 className="text-2xl font-bold mb-4">Order {order._id}</h2>
          <div className="mb-4">
            <p><strong>Name:</strong> {order.userName}</p>
            <p><strong>Phone:</strong> {order.userPhone}</p>
            {
              order.paymentStatus === 'paid' && (
                <p className='text-green-500'><strong>Payment Status:</strong> {order.paymentStatus}</p>
              )
            }
            {
              order.paymentStatus === 'unpaid' && (
                <p className='text-red-500'><strong>Payment Status:</strong> {order.paymentStatus}</p>
              )
            }
          </div>
          <ul className="divide-y divide-gray-200 mb-4">
            {order.products.map((product, index) => (
              <li key={index} className="flex justify-between items-center py-2">
                <div className="flex items-center space-x-2">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-12 h-12 object-cover rounded-md"
                  />
                  <div>
                    <h3 className="font-semibold">{product.name}</h3>
                    <p className="text-sm text-gray-500">${product.price.toFixed(2)} x {product.quantity}</p>
                  </div>
                </div>
                <span>${(product.price * product.quantity).toFixed(2)}</span>
              </li>
            ))}
          </ul>
          <ul className="divide-y divide-gray-200 mb-4">
            {order.selectedFeatures.map((product, index) => (
              <li key={index} className="flex justify-between items-center py-2">
                <div className="flex items-center space-x-2">
                  <div>
                    <h3 className="font-semibold">{product.name}</h3>
                  </div>
                </div>
                <span>${(product.price)}</span>
              </li>
            ))}
          </ul>
          <div className="flex justify-between items-center">
            <span className="font-semibold">Total Amount:</span>
            <span>${order.amount[0]}</span>
          </div>
          {order.status === 'pending' && (
            <button
              onClick={() => markAsPickedUp(order._id)}
              className="mt-4 w-full bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded-md"
            >
              Mark as Picked Up
            </button>
          )}
        </div>
      ))
  );

  const renderPendingOrders = () => {
    const filteredOrders = pendingOrders.filter(order => order.paymentStatus === activeTab);
    return renderOrderList(filteredOrders);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Order List</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 className="text-2xl font-bold mb-4">Pending Orders</h2>

          {/* Sekmeler */}
          <div className="flex mb-4">
            <button
              className={`mr-4 ${activeTab === 'paid' ? 'text-orange-500 border-b-2 border-orange-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('paid')}
            >
              Paid
            </button>
            <button
              className={`${activeTab === 'unpaid' ? 'text-orange-500 border-b-2 border-orange-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('unpaid')}
            >
              Unpaid
            </button>
          </div>

          {/* Sipariş Listesi */}
          {renderPendingOrders()}
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">Picked Up Orders</h2>
          {renderOrderList(pickedUpOrders)}
        </div>
      </div>
    </div>
  );
}

export default OrderPage;
