import React from 'react';
import { Link } from 'react-router-dom';

const ProductList = ({ products, categories, onDelete, handleSelect, selectedItems, swapOrders }) => {
  // Create a lookup table for category names
  const categoryLookup = categories.reduce((acc, category) => {
    acc[category._id] = category.name;
    return acc;
  }, {});

  // Group products by category
  const groupedProducts = products.reduce((acc, product) => {
    const category = categoryLookup[product.category] || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(product);
    return acc;
  }, {});

  // Sort products by order field in descending order
  Object.keys(groupedProducts).forEach((category) => {
    groupedProducts[category].sort((a, b) => b.order - a.order);
  });

  return (
    <div>
      <h2 className="text-2xl font-bold m-4">Product List</h2>
      <button
        onClick={swapOrders}
        disabled={selectedItems.length !== 2}
        className={`px-4 py-2 rounded transition duration-300 
              ${selectedItems.length === 2 ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-300 text-gray-500 cursor-not-allowed'} 
              focus:outline-none`}
      >
        Sıraları Değiştir
      </button>
      {Object.keys(groupedProducts).map((category) => (
        <div key={category}>
          <h3 className="text-xl font-bold m-4 mt-10 text-red-700">{category}</h3>
          <ul className="divide-y divide-gray-200 grid lg:grid-cols-2 gap-8">
            {groupedProducts[category].map((product) => (
              <li key={product._id} className="py-4 flex justify-between items-center shadow-lg grid-span-1 m-10 mt-1">
                <div>
                  <h3 className="text-xl font-semibold underline">Name: {product.name}</h3>
                  <h1 className="text-xl font-semibold">Category: {category}</h1>
                  <div className="flex">
                    <p className="font-bold">Price:</p> <p>${product.price}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">Description:</p> <p>{product.description}</p>
                  </div>
                  <ul className="text-gray-600">
                    {product.features.map((feature, index) => (
                      <li key={index}>
                        <div className="font-bold">{feature.title}--</div>
                        {feature.name}: ${feature.price}
                      </li>
                    ))}
                  </ul>
                </div>
                <button
                  onClick={() => handleSelect(product)}
                  className={`px-4 py-2 rounded transition duration-300 
              ${selectedItems.includes(product) ? 'bg-green-500 text-white' : 'bg-gray-300 text-black'} 
              hover:bg-green-400 hover:text-white`}
                >
                  {selectedItems.includes(product) ? "Seçildi" : "Seç"}
                </button>
                <div>
                  <Link
                    to={{
                      pathname: "/admin/edit/" + product._id,
                    }}
                    className="m-2 bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => onDelete(product._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ProductList;
